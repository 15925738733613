<template>
  <b-card>
    <div>
      <!-- search input -->
      <div class="custom-search d-flex align-items-center justify-content-end">
        <div
          class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
        >
        <b-form-input
            v-on:keyup="onSearch"
            v-model.lazy="searchTerm"
            placeholder="Search..."
            type="text"
            class="d-inline-block mr-sm-1 mb-1 mb-sm-0"
          />
          <template
            v-if="$permissionAbility(OVER_TIME_POLICY_CREATE, permissions)"
          >
            <b-button
              class="flex-shrink-0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-on:click="showModal"
            >
              Create
            </b-button>
          </template>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
          styleClass="vgt-table table-custom-style striped"
          :line-numbers="false"
        mode="remote"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        :isLoading.sync="isLoading"
        :rows="rows"
        :columns="columns"
        :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [{ field: 'created_at', type: 'desc' }],
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">

          <template v-if="props.column.field === 'format_title'">
            <span>
              <b class="cursor-pointer" v-if="$permissionAbility(OVER_TIME_POLICY_SHOW, permissions)" v-on:click="onShowPage(props.row)">{{ props?.row?.title }} </b>
              <b v-else>{{ props?.row?.title }} </b>
            </span>
          </template>

          <template v-if="props.column.field === 'format_currency'">

            <FormatCurrency :type="'currency'"  :currency="defaultCurrency" />

          </template>

          <!-- working_day_amount -->
          <template v-if="props?.column?.field === 'format_working_day_amount'">

            <div
              v-if="props?.row?.working_day_amount_value_type == 'multiplied'"
            >
              {{ props.row?.working_day_amount_value + ' X Hourly Salary' }}
            </div>
            <div
                v-else-if="props?.row?.working_day_amount_value_type == 'extra_added_with_hourly_rate'"
            >
              {{ props.row?.working_day_amount_value + ' + Hourly Salary' }}
            </div>

            <div
                v-else-if="props?.row?.working_day_amount_value_type == 'extra_added_with_monthly_salary'"
            >
              {{ props.row?.working_day_amount_value + ' + Monthly Salary' }}
            </div>
            <div v-else>
              <FormatCurrency :amount="props.row?.working_day_amount_value" :currency="defaultCurrency" />
            </div>
          </template>

          <!-- off_day_amount -->
          <template v-if="props?.column?.field === 'format_off_day_amount'">
            <div v-if="props?.row?.off_day_amount_value_type == 'multiplied'">

              {{ props.row?.off_day_amount_value + ' X Hourly Salary' }}

            </div>
            <div
                v-else-if="props?.row?.off_day_amount_value_type == 'extra_added_with_hourly_rate'"
            >
              {{ props.row?.working_day_amount_value + ' + Hourly Salary' }}
            </div>

            <div
                v-else-if="props?.row?.off_day_amount_value_type == 'extra_added_with_monthly_salary'"
            >
              {{ props.row?.working_day_amount_value + ' + Monthly Salary' }}
            </div>
            <div v-else>
              {{ props.row?.off_day_amount_value }}
            </div>
          </template>

          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <template
                  v-if="$permissionAbility(OVER_TIME_POLICY_EDIT, permissions)"
                >
                  <b-dropdown-item v-on:click="onShow(props.row)">
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Edit</span>
                  </b-dropdown-item>
                </template>
                <template
                  v-if="$permissionAbility(OVER_TIME_POLICY_SHOW, permissions)"
                >
                  <b-dropdown-item v-on:click="onShowPage(props.row)">
                    <feather-icon icon="EyeIcon" class="mr-50" />
                    <span>Show</span>
                  </b-dropdown-item>
                </template>
                <template
                  v-if="
                    $permissionAbility(OVER_TIME_POLICY_DELETE, permissions)
                  "
                >
                  <b-dropdown-item v-on:click="onDelete(props.row.id)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Delete</span>
                  </b-dropdown-item>
                </template>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '25', '50', '100', '500']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>

    <b-modal
      id="modal-overtime-policy-form"
      centered
      :title="
        modelType == 'editModel'
          ? 'Edit Overtime Policy'
          : 'Create Overtime Policy'
      "
      hide-footer
      @hidden="hiddenModal"
      no-close-on-backdrop
      size="lg"
    >

      <validation-observer ref="overtimePolicyValidation">
        <b-form v-on:submit.prevent="validationForm">

          <!-- Overtime Policy title -->
          <b-form-group label="Policy" label-for="title" class="required-label">
            <validation-provider
              #default="{ errors }"
              name="over time title"
              vid="title"
              rules="required|max:255"
            >
              <b-form-input
                id="title"
                type="text"
                v-model="overtimePolicyName"
                :state="errors.length > 0 ? false : null"
                placeholder="Enter Title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-row>
            <b-col cols="6">
              <!-- Overtime working day amount value type -->
              <b-form-group
                  label="For (working day)"
                  label-for="working_day_amount_value_type"
                  class="required-label"
              >
                <ValidationProvider
                    name="For(Salary type as working day)"
                    v-slot="{ errors }"
                    vid="working_day_amount_value_type"
                    rules="required"
                >
                  <v-select
                    id="working_day_amount_value_type"
                    v-model="workingDayAmountValueType"
                    :options="filteredAmountValueTypeConstants"
                    :reduce="(option) => option.value"
                    label="name"
                    class="custom-font"
                    :searchable="false"
                    placeholder="Choose Here"
                  />

                  <small class="text-success font-weight-bold" v-if="workingDayAmountValueType === 'multiplied'"> <feather-icon icon="InfoIcon" /> Example: At a rate of $20 per hour, working 8 hours with an overtime rate of $12.50, the overtime amount totals $260</small>
                  <small class="text-success font-weight-bold" v-else-if="workingDayAmountValueType === 'extra_added_with_hourly_rate'"> <feather-icon icon="InfoIcon" /> Example: Adding $20 to an hourly rate of $12.50, the overtime amount is $32.50.   </small>
                  <small class="text-success font-weight-bold" v-else-if="workingDayAmountValueType === 'extra_added_with_monthly_salary'"> <feather-icon icon="InfoIcon" />Example: Adding a monthly salary of $3000 with an additional $200,the overtime amount is $3200</small>
                  <small class="text-success font-weight-bold" v-else-if="workingDayAmountValueType === 'flat'"> <feather-icon icon="InfoIcon" />
                    Example:-For an amount of USD 20 per hour and 8 hours of work, the flat hourly rate totals USD 160.</small>

                  <div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <!-- Overtime working day amount value -->
              <b-form-group
                  :label="workingDayAmountValueType === 'multiplied' ? 'Multiplied by' : 'Amount'"
                  label-for="working_day_amount_value"
                  class="required-label"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="workingDayAmountValueType === 'multiplied' ? 'Multiplied by' : 'Amount'"
                    vid="working_day_amount_value"
                    rules="required|between:0,999999.99"
                >
                  <b-form-input
                      id="working_day_amount_value"
                      type="text"
                      v-model="workingDayAmountValue"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="workingDayAmountValueType === 'multiplied' ? 'Enter multiplied by value' : 'Enter amount value'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="6">
              <!-- Overtime off day amount value type -->
              <b-form-group
                  label="And for (off day)"
                  label-for="off_day_amount_value_type"
                  class="required-label"
              >
                <ValidationProvider
                    name="Salary Type (Off Day)"
                    v-slot="{ errors }"
                    vid="off_day_amount_value_type"
                    rules="required"
                >
                  <v-select
                    id="off_day_amount_value_type"
                    v-model="offDayAmountValueType"
                    :options="filteredAmountValueTypeConstants"
                    :reduce="(option) => option.value"
                    placeholder="Choose Here"
                    label="name"
                    class="custom-font"
                    :searchable="false"
                  />

                  <small class="text-success font-weight-bold" v-if="offDayAmountValueType === 'multiplied'"> <feather-icon icon="InfoIcon" /> Example: At a rate of $20 per hour, working 8 hours with an overtime rate of $12.50, the overtime amount totals $260</small>
                  <small class="text-success font-weight-bold" v-else-if="offDayAmountValueType === 'extra_added_with_hourly_rate'"> <feather-icon icon="InfoIcon" /> Example: Adding $20 to an hourly rate of $12.50, the overtime amount is $32.50.   </small>
                  <small class="text-success font-weight-bold" v-else-if="offDayAmountValueType === 'extra_added_with_monthly_salary'"> <feather-icon icon="InfoIcon" />Example: Adding a monthly salary of $3000 with an additional $200,the overtime amount is $3200</small>
                  <small class="text-success font-weight-bold" v-else-if="offDayAmountValueType === 'flat'"> <feather-icon icon="InfoIcon" />
                    Example:-For an amount of USD 20 per hour and 8 hours of work, the flat hourly rate totals USD 160.</small>

                </ValidationProvider>
              </b-form-group>

            </b-col>
            <b-col cols="6">
              <!-- Overtime off day amount value -->
              <b-form-group
                  :label="offDayAmountValueType === 'multiplied' ? 'Multiplied by' : 'Amount'"
                  label-for="off_day_amount_value"
                  class="required-label"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="offDayAmountValueType === 'multiplied' ? 'Multiplied by' : 'Amount'"
                    vid="off_day_amount_value"
                    rules="required|between:0,999999.99"
                >
                  <b-form-input
                      id="off_day_amount_value"
                      type="text"
                      v-model="offDayAmountValue"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="offDayAmountValueType === 'multiplied' ? 'Enter multiplied by value' : 'Enter amount value'"
                      step="0.01"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- loading button -->
          <template v-if="isOverTimePolicyFormSubmitLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              type="submit"
              class="float-right"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

  <script>
  import {
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BForm,
    BModal,
    BRow,
    BSpinner, VBTooltip, BCol,
  } from 'bootstrap-vue'
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import { OverTimePolicyAmountValueTypeConstant } from "@/helpers/constant/OverTimePolicyAmountValueTypeConstant";

import {
  OVER_TIME_POLICY_CREATE,
  OVER_TIME_POLICY_EDIT,
  OVER_TIME_POLICY_DELETE,
  OVER_TIME_POLICY_SHOW,
} from "@/helpers/permissionsConstant";
import FormatCurrency from "@/layouts/components/FormatCurrency.vue";

export default {
  name: "OverTimePolicyView",
  components: {
    BCol,
    BForm,
    BRow,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    FormatCurrency
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      OVER_TIME_POLICY_CREATE,
      OVER_TIME_POLICY_EDIT,
      OVER_TIME_POLICY_DELETE,
      OVER_TIME_POLICY_SHOW,

      modelType: "",
      name: "",
      defaultCurrency: "",

      // salary policy
      overtimePolicyId: "",
      OverTimePolicyAmountValueTypeConstant,
      filteredAmountValueTypeConstants: [],
      overtimePolicyName: "",
      workingDayAmountValue: 0,
      offDayAmountValueType: "",
      offDayAmountValue: 0,
      workingDayAmountValueType: "",

      pageLength: 10,
      columns: [
        {
          label: "Name",
          field: "format_title",
          sortable: false,
        },
        {
          label: "Currency",
          field: "format_currency",
        },
        {
          label: "Salary Amount for Per Hour (Working Day)",
          field: "format_working_day_amount",
          sortable: false,
        },
        {
          label: "Salary Amount for Per Hour (Off Day)",
          field: "format_off_day_amount",
          sortable: false,
        },
        {
          label: "Created On",
          field: "created_at",
          formatFn: this.formatDateTime,
          sortable: true,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      isOverTimePolicyFormSubmitLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: "id", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 10,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
    statusVariant() {
      const statusColor = {
        true: "light-success",
        false: "light-danger",
      };

      return (status) => statusColor[status];
    },
  },

  async created() {
    try {

      const [getSetting, getCurrencies] =
          await Promise.all([
            this.getSetting({
              select:
                  "currency_id",
            }),
            this.getCurrencies(),
          ]);

      // getSetting
      const currencyId = getSetting?.data?.data?.currency_id;

      // getCurrencies
      const currencyData = getCurrencies?.data?.data?.filter((item) => {
        return item?.id === currencyId
      });

      this.defaultCurrency = currencyData?.[0]?.currency;

      this.loadItems();
      this.filterAmountType();
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {

    async getSetting(params) {
      return await this.$api.get("/api/settings?includes=currency");
    },
    async getCurrencies() {
      return await this.$api.get("/api/currencies/all");
    },
    onShowPage(value) {
      let policyCategoryName = "over-time-policy";

      let policyCategoryId = value?.policy_category_id;
      let id = value?.id;
      this.$router.push({
        name: "admin-payroll-policy-assign-details",
        params: { policyCategoryName, policyCategoryId, id },
      });
    },
    showModal() {
      this.$bvModal.show("modal-overtime-policy-form");
    },

    hiddenModal() {
      this.$bvModal.hide("modal-overtime-policy-form");
      this.resetModal();
    },

    resetModal() {
      this.modelType = "";
      this.overtimePolicyId = "";
      this.overtimePolicyName = "";
      this.workingDayAmountValue = "";
      this.offDayAmountValue = "";
      this.workingDayAmountValueType = "";
      this.offDayAmountValueType = "";
    },

    onShow(value) {
      this.modelType = "editModel";
      this.overtimePolicyId = value.id;
      this.overtimePolicyName = value.title;
      this.workingDayAmountValueType = value.working_day_amount_value_type;
      this.workingDayAmountValue = value.working_day_amount_value;
      this.offDayAmountValueType = value.off_day_amount_value_type;
      this.offDayAmountValue = value.off_day_amount_value;

      this.showModal();
    },

    formatDateTime(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }
    },

    filterAmountType() {
      this.filteredAmountValueTypeConstants =
        this.OverTimePolicyAmountValueTypeConstant.filter(
          (item) => item.value
        );
    },
    async onDelete(id) {
      this.$swal({
        title: "Warning!",
        text: "Are You Sure You Want To Delete This?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/over-time-policies/${id}`);

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Overtime Policy Successfully Deleted",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    async getOverTimePolicyItems(params) {
      return await this.$api.get("api/over-time-policies", {
        params: {
          show: params.show,
          page: params.page,
          sort: params.sort,
          q: params.q,
        },
      });
    },

    async loadItems() {
      try {

        const overtimePolicies = await this.getOverTimePolicyItems(
          {
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
          }
        );

        const data = overtimePolicies?.data?.data;
        const meta = overtimePolicies?.data?.meta;

        this.totalRecords = meta?.pagination?.total;
        this.rows = data;
      } catch (error) {

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    validationForm: async function () {
      this.$refs.overtimePolicyValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.$refs.overtimePolicyValidation.reset();
            if (this.modelType == "editModel") {
              this.isOverTimePolicyFormSubmitLoading = true;
              await this.$api.put(
                `/api/over-time-policies/${this.overtimePolicyId}`,
                {
                  title: this.overtimePolicyName,
                  working_day_amount_value_type: this.workingDayAmountValueType,
                  working_day_amount_value: this.workingDayAmountValue,
                  off_day_amount_value_type: this.offDayAmountValueType,
                  off_day_amount_value: this.offDayAmountValue,
                }
              );
              this.isOverTimePolicyFormSubmitLoading = false;
              this.loadItems();

              this.hiddenModal();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Over Time Policy Successfully Updated",
                },
              });
            } else {
              this.isOverTimePolicyFormSubmitLoading = true;
              await this.$api.post("/api/over-time-policies", {
                title: this.overtimePolicyName,
                working_day_amount_value_type: this.workingDayAmountValueType,
                working_day_amount_value: this.workingDayAmountValue,
                off_day_amount_value_type: this.offDayAmountValueType,
                off_day_amount_value: this.offDayAmountValue,
              });
              this.isOverTimePolicyFormSubmitLoading = false;
              this.hiddenModal();

              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "OverTime Policy Successfully Created",
                },
              });
            }
          } catch (error) {
            this.isOverTimePolicyFormSubmitLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.overtimePolicyValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

  <style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.required-label label::after {
  content: " *";
  color: red;
}
</style>

