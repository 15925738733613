var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('div', [_c('div', {
    staticClass: "custom-search d-flex align-items-center justify-content-end"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
  }, [_c('b-form-input', {
    staticClass: "d-inline-block mr-sm-1 mb-1 mb-sm-0",
    attrs: {
      "placeholder": "Search...",
      "type": "text"
    },
    on: {
      "keyup": _vm.onSearch
    },
    model: {
      value: _vm.searchTerm,
      callback: function callback($$v) {
        _vm.searchTerm = $$v;
      },
      expression: "searchTerm"
    }
  }), _vm.$permissionAbility(_vm.OVER_TIME_POLICY_CREATE, _vm.permissions) ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.showModal
    }
  }, [_vm._v(" Create ")])] : _vm._e()], 2)]), _c('vue-good-table', {
    attrs: {
      "styleClass": "vgt-table table-custom-style striped",
      "line-numbers": false,
      "mode": "remote",
      "totalRows": _vm.totalRecords,
      "isLoading": _vm.isLoading,
      "rows": _vm.rows,
      "columns": _vm.columns,
      "sort-options": {
        enabled: false,
        multipleColumns: true,
        initialSortBy: [{
          field: 'created_at',
          type: 'desc'
        }]
      },
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange,
      "update:isLoading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$row, _props$row2, _props$column, _props$row3, _props$row4, _props$row5, _props$row6, _props$row7, _props$row8, _props$row9, _props$column2, _props$row10, _props$row11, _props$row12, _props$row13, _props$row14, _props$row15, _props$row16;
        return [props.column.field === 'format_title' ? [_c('span', [_vm.$permissionAbility(_vm.OVER_TIME_POLICY_SHOW, _vm.permissions) ? _c('b', {
          staticClass: "cursor-pointer",
          on: {
            "click": function click($event) {
              return _vm.onShowPage(props.row);
            }
          }
        }, [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row = props.row) === null || _props$row === void 0 ? void 0 : _props$row.title) + " ")]) : _c('b', [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row2 = props.row) === null || _props$row2 === void 0 ? void 0 : _props$row2.title) + " ")])])] : _vm._e(), props.column.field === 'format_currency' ? [_c('FormatCurrency', {
          attrs: {
            "type": 'currency',
            "currency": _vm.defaultCurrency
          }
        })] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column = props.column) === null || _props$column === void 0 ? void 0 : _props$column.field) === 'format_working_day_amount' ? [(props === null || props === void 0 ? void 0 : (_props$row3 = props.row) === null || _props$row3 === void 0 ? void 0 : _props$row3.working_day_amount_value_type) == 'multiplied' ? _c('div', [_vm._v(" " + _vm._s(((_props$row4 = props.row) === null || _props$row4 === void 0 ? void 0 : _props$row4.working_day_amount_value) + ' X Hourly Salary') + " ")]) : (props === null || props === void 0 ? void 0 : (_props$row5 = props.row) === null || _props$row5 === void 0 ? void 0 : _props$row5.working_day_amount_value_type) == 'extra_added_with_hourly_rate' ? _c('div', [_vm._v(" " + _vm._s(((_props$row6 = props.row) === null || _props$row6 === void 0 ? void 0 : _props$row6.working_day_amount_value) + ' + Hourly Salary') + " ")]) : (props === null || props === void 0 ? void 0 : (_props$row7 = props.row) === null || _props$row7 === void 0 ? void 0 : _props$row7.working_day_amount_value_type) == 'extra_added_with_monthly_salary' ? _c('div', [_vm._v(" " + _vm._s(((_props$row8 = props.row) === null || _props$row8 === void 0 ? void 0 : _props$row8.working_day_amount_value) + ' + Monthly Salary') + " ")]) : _c('div', [_c('FormatCurrency', {
          attrs: {
            "amount": (_props$row9 = props.row) === null || _props$row9 === void 0 ? void 0 : _props$row9.working_day_amount_value,
            "currency": _vm.defaultCurrency
          }
        })], 1)] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column2 = props.column) === null || _props$column2 === void 0 ? void 0 : _props$column2.field) === 'format_off_day_amount' ? [(props === null || props === void 0 ? void 0 : (_props$row10 = props.row) === null || _props$row10 === void 0 ? void 0 : _props$row10.off_day_amount_value_type) == 'multiplied' ? _c('div', [_vm._v(" " + _vm._s(((_props$row11 = props.row) === null || _props$row11 === void 0 ? void 0 : _props$row11.off_day_amount_value) + ' X Hourly Salary') + " ")]) : (props === null || props === void 0 ? void 0 : (_props$row12 = props.row) === null || _props$row12 === void 0 ? void 0 : _props$row12.off_day_amount_value_type) == 'extra_added_with_hourly_rate' ? _c('div', [_vm._v(" " + _vm._s(((_props$row13 = props.row) === null || _props$row13 === void 0 ? void 0 : _props$row13.working_day_amount_value) + ' + Hourly Salary') + " ")]) : (props === null || props === void 0 ? void 0 : (_props$row14 = props.row) === null || _props$row14 === void 0 ? void 0 : _props$row14.off_day_amount_value_type) == 'extra_added_with_monthly_salary' ? _c('div', [_vm._v(" " + _vm._s(((_props$row15 = props.row) === null || _props$row15 === void 0 ? void 0 : _props$row15.working_day_amount_value) + ' + Monthly Salary') + " ")]) : _c('div', [_vm._v(" " + _vm._s((_props$row16 = props.row) === null || _props$row16 === void 0 ? void 0 : _props$row16.off_day_amount_value) + " ")])] : _vm._e(), props.column.field === 'action' ? _c('span', [_c('span', [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_vm.$permissionAbility(_vm.OVER_TIME_POLICY_EDIT, _vm.permissions) ? [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.onShow(props.row);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "Edit2Icon"
          }
        }), _c('span', [_vm._v("Edit")])], 1)] : _vm._e(), _vm.$permissionAbility(_vm.OVER_TIME_POLICY_SHOW, _vm.permissions) ? [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.onShowPage(props.row);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "EyeIcon"
          }
        }), _c('span', [_vm._v("Show")])], 1)] : _vm._e(), _vm.$permissionAbility(_vm.OVER_TIME_POLICY_DELETE, _vm.permissions) ? [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.onDelete(props.row.id);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "TrashIcon"
          }
        }), _c('span', [_vm._v("Delete")])], 1)] : _vm._e()], 2)], 1)]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '25', '50', '100', '500']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "id": "modal-overtime-policy-form",
      "centered": "",
      "title": _vm.modelType == 'editModel' ? 'Edit Overtime Policy' : 'Create Overtime Policy',
      "hide-footer": "",
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    on: {
      "hidden": _vm.hiddenModal
    }
  }, [_c('validation-observer', {
    ref: "overtimePolicyValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.validationForm($event);
      }
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Policy",
      "label-for": "title"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "over time title",
      "vid": "title",
      "rules": "required|max:255"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "title",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter Title"
          },
          model: {
            value: _vm.overtimePolicyName,
            callback: function callback($$v) {
              _vm.overtimePolicyName = $$v;
            },
            expression: "overtimePolicyName"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "For (working day)",
      "label-for": "working_day_amount_value_type"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "For(Salary type as working day)",
      "vid": "working_day_amount_value_type",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "working_day_amount_value_type",
            "options": _vm.filteredAmountValueTypeConstants,
            "reduce": function reduce(option) {
              return option.value;
            },
            "label": "name",
            "searchable": false,
            "placeholder": "Choose Here"
          },
          model: {
            value: _vm.workingDayAmountValueType,
            callback: function callback($$v) {
              _vm.workingDayAmountValueType = $$v;
            },
            expression: "workingDayAmountValueType"
          }
        }), _vm.workingDayAmountValueType === 'multiplied' ? _c('small', {
          staticClass: "text-success font-weight-bold"
        }, [_c('feather-icon', {
          attrs: {
            "icon": "InfoIcon"
          }
        }), _vm._v(" Example: At a rate of $20 per hour, working 8 hours with an overtime rate of $12.50, the overtime amount totals $260")], 1) : _vm.workingDayAmountValueType === 'extra_added_with_hourly_rate' ? _c('small', {
          staticClass: "text-success font-weight-bold"
        }, [_c('feather-icon', {
          attrs: {
            "icon": "InfoIcon"
          }
        }), _vm._v(" Example: Adding $20 to an hourly rate of $12.50, the overtime amount is $32.50. ")], 1) : _vm.workingDayAmountValueType === 'extra_added_with_monthly_salary' ? _c('small', {
          staticClass: "text-success font-weight-bold"
        }, [_c('feather-icon', {
          attrs: {
            "icon": "InfoIcon"
          }
        }), _vm._v("Example: Adding a monthly salary of $3000 with an additional $200,the overtime amount is $3200")], 1) : _vm.workingDayAmountValueType === 'flat' ? _c('small', {
          staticClass: "text-success font-weight-bold"
        }, [_c('feather-icon', {
          attrs: {
            "icon": "InfoIcon"
          }
        }), _vm._v(" Example:-For an amount of USD 20 per hour and 8 hours of work, the flat hourly rate totals USD 160.")], 1) : _vm._e(), _c('div', [_c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": _vm.workingDayAmountValueType === 'multiplied' ? 'Multiplied by' : 'Amount',
      "label-for": "working_day_amount_value"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.workingDayAmountValueType === 'multiplied' ? 'Multiplied by' : 'Amount',
      "vid": "working_day_amount_value",
      "rules": "required|between:0,999999.99"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "working_day_amount_value",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": _vm.workingDayAmountValueType === 'multiplied' ? 'Enter multiplied by value' : 'Enter amount value'
          },
          model: {
            value: _vm.workingDayAmountValue,
            callback: function callback($$v) {
              _vm.workingDayAmountValue = $$v;
            },
            expression: "workingDayAmountValue"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "And for (off day)",
      "label-for": "off_day_amount_value_type"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "Salary Type (Off Day)",
      "vid": "off_day_amount_value_type",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "off_day_amount_value_type",
            "options": _vm.filteredAmountValueTypeConstants,
            "reduce": function reduce(option) {
              return option.value;
            },
            "placeholder": "Choose Here",
            "label": "name",
            "searchable": false
          },
          model: {
            value: _vm.offDayAmountValueType,
            callback: function callback($$v) {
              _vm.offDayAmountValueType = $$v;
            },
            expression: "offDayAmountValueType"
          }
        }), _vm.offDayAmountValueType === 'multiplied' ? _c('small', {
          staticClass: "text-success font-weight-bold"
        }, [_c('feather-icon', {
          attrs: {
            "icon": "InfoIcon"
          }
        }), _vm._v(" Example: At a rate of $20 per hour, working 8 hours with an overtime rate of $12.50, the overtime amount totals $260")], 1) : _vm.offDayAmountValueType === 'extra_added_with_hourly_rate' ? _c('small', {
          staticClass: "text-success font-weight-bold"
        }, [_c('feather-icon', {
          attrs: {
            "icon": "InfoIcon"
          }
        }), _vm._v(" Example: Adding $20 to an hourly rate of $12.50, the overtime amount is $32.50. ")], 1) : _vm.offDayAmountValueType === 'extra_added_with_monthly_salary' ? _c('small', {
          staticClass: "text-success font-weight-bold"
        }, [_c('feather-icon', {
          attrs: {
            "icon": "InfoIcon"
          }
        }), _vm._v("Example: Adding a monthly salary of $3000 with an additional $200,the overtime amount is $3200")], 1) : _vm.offDayAmountValueType === 'flat' ? _c('small', {
          staticClass: "text-success font-weight-bold"
        }, [_c('feather-icon', {
          attrs: {
            "icon": "InfoIcon"
          }
        }), _vm._v(" Example:-For an amount of USD 20 per hour and 8 hours of work, the flat hourly rate totals USD 160.")], 1) : _vm._e()];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": _vm.offDayAmountValueType === 'multiplied' ? 'Multiplied by' : 'Amount',
      "label-for": "off_day_amount_value"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.offDayAmountValueType === 'multiplied' ? 'Multiplied by' : 'Amount',
      "vid": "off_day_amount_value",
      "rules": "required|between:0,999999.99"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "off_day_amount_value",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": _vm.offDayAmountValueType === 'multiplied' ? 'Enter multiplied by value' : 'Enter amount value',
            "step": "0.01"
          },
          model: {
            value: _vm.offDayAmountValue,
            callback: function callback($$v) {
              _vm.offDayAmountValue = $$v;
            },
            expression: "offDayAmountValue"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _vm.isOverTimePolicyFormSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }